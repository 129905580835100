import authHttp from "./authHttp";

// export const getclientManagementListApi = async (payload) => {
//   const url = `api/all-users/${payload.type}?page=${payload.page}`;
//   const {
//     data: { data },
//   } = await authHttp.get(url);
//   return data;
// };

// Api change for above function
export const getclientManagementListApi = async (payload) => {
  const url = `api/all-users`;
  const configuration = payload.export && { responseType: "blob" };
  if (payload.export) {
    const { data } = await authHttp.post(url, payload, configuration);
    return data;
  } else {
    const {
      data: { data },
    } = await authHttp.post(url, payload);
    return data;
  }
};

export const exportClientManagementData = async (payload) => {
  const url = `api/all-users`;
  const configuration = { responseType: "blob" };
  const { data } = await authHttp.post(url, payload, configuration);
  return data;
};

export const createCaret = async (payload) => {
  const url = "api/exchange-caret";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const getUserDetails = async (payload) => {
  const url = `api/users/${payload.id}`;
  const { data } = await authHttp.get(url);
  return data?.data;
};

export const cancelSubscription = async (payload) => {
  const url = `api/subscriptions/cancel/${payload.id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const addUserNotes = async (payload) => {
  const url = `api/add-notes/users`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const userConvertToAlaCarte = async (payload) => {
  const url = `/api/convert-to-alacarte/${payload?.id}`;
  const { data } = await authHttp.post(url);
  // const { data } = await authHttp.post(url, payload);
  return data;
};

export const deleteClient = async (payload) => {
  const url = `/api/delete-client/${payload?.id}`;
  const { data } = await authHttp.del(url);
  // const { data } = await authHttp.del(url, payload);
  return data;
};

export const cancelUserSubscription = async (payload) => {
  const url = `/api/cancel-subscriptions`;
  const { data } = await authHttp.post(url, payload);
  return data;
};
